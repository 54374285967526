import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BiHomeSmile, BiBookmarks, BiCog, BiLogOut, BiMessageDetail } from 'react-icons/bi'
import Swal from 'sweetalert2'

const DashboardTab = ({ label, icon, path, active, handleClick }) => {

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div>
          <button onClick={ path ? () => navigate(path) : handleClick } className={`dashboard-tab ${active && 'active'}`}>
            <div>{icon}</div>
            <span>{label}</span>
          </button>
      </div>
    </React.Fragment>
  )
}

const handleLogout = () => {
  Swal.fire({
    title: 'Are you sure?',
    text: 'Once you clicked on logout you will logged out',
    icon: 'question',
    showCancelButton: true,
    cancelButtonText: "Cancel",
    confirmButtonText: "Logout",
  }).then((result) => {
    if (result.isConfirmed) {
      localStorage.removeItem('accessToken');
      window.location = "/";
    }
  });
}

const DashboardSidebar = () => {

  const { pathname } = useLocation();

  const tabData = [
    {
      label: "Dashboard",
      icon: <BiHomeSmile size={20} />,
      path: "/dashboard/account"
    },
    {
      label: "My Quotes",
      icon: <BiMessageDetail size={20} />,
      path: "/dashboard/quote"
    },
    {
      label: "My Bookings",
      icon: <BiBookmarks size={20} />,
      path: "/dashboard/booking"
    },
    {
      label: "Settings",
      icon: <BiCog size={20} />,
      path: "/dashboard/setting"
    }
  ];

  return (
    <React.Fragment>
      <aside>
        <div className='space-y-2'>
          {tabData.map(({ label, path, icon }) => (
            <DashboardTab key={label} label={label} icon={icon} path={path} active={(pathname.includes(path))} />
          ))}
          <DashboardTab label={"Logout"} icon={<BiLogOut size={20} />} handleClick={() => handleLogout()} />
        </div>
      </aside>
    </React.Fragment>
  )
}

export default DashboardSidebar