import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import PageHeader from "../../components/Common/PageHeader/PageHeader";
import { ToastContainer, toast } from "react-toastify";
import { FaLocationDot } from "react-icons/fa6";
import { MdPeople } from "react-icons/md";
import { RiFileList2Line } from "react-icons/ri";
import { MdOutlineDescription } from "react-icons/md";
import Divider from "@mui/material/Divider";

// importing the state data from here

import { StateData } from "../../constants/StatesAndDistricts";

const Careers = () => {
  const applicationFormRef = useRef(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [resume, setResume] = useState(null);
  const [name, setName] = useState("");
  const [fathersName, setFathersName] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [dob, setDob] = useState("");
  const [homeContact, setHomeContact] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [districts, setDistricts] = useState([]);
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [country, setCountry] = useState("");
  const [experience, setExperience] = useState("");
  const [jobCards, setJobCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [error, setError] = useState(null);
  const [isFresher, setIsFresher] = useState(false);
  const [employerName, setEmployerName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [experienceDuration, setExperienceDuration] = useState("");
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");

  const [industry, setIndustry] = useState("");
  const [role, setRole] = useState("");
  const [reasonLeaving, setReasonLeaving] = useState("");
  const [filteredStates, setFilteredStates] = useState(StateData.states);
  const [filteredDistricts, setFilteredDistricts] = useState([]);

  // Previous Employment Information
  const [employerState, setEmployerState] = useState("");
  const [employerDistrict, setEmployerDistrict] = useState("");
  const [employerCity, setEmployerCity] = useState("");
  const [employerDistricts, setEmployerDistricts] = useState([]);
  const [filteredEmployerStates, setFilteredEmployerStates] = useState(
    StateData.states
  );
  const [filteredEmployerDistricts, setEmployerFilteredDistricts] = useState(
    []
  );

  const [educationFields, setEducationFields] = useState([
    {
      instituteName: "",
      educationLevel: "",
      courseName: "",
      specialization: "",
      duration: "",
      yearOfPassing: "",
      finalGrade: "",
    },
  ]);

  // Function to handle input changes in each education field
  const handleEducationChange = (index, event) => {
    const values = [...educationFields];
    values[index][event.target.name] = event.target.value;
    setEducationFields(values);
  };

  // Function to add a new education field
  const handleAddEducation = () => {
    setEducationFields([
      ...educationFields,
      {
        instituteName: "",
        educationLevel: "",
        courseName: "",
        specialization: "",
        duration: "",
        yearOfPassing: "",
        finalGrade: "",
      },
    ]);
  };

  //Personal Profile Information

  useEffect(() => {
    if (state) {
      const selectedState = StateData.states.find((s) => s.state === state);
      if (selectedState) {
        setDistricts(selectedState.districts); // Update districts based on selected state
        setDistrict(""); // Reset district when state changes
      } else {
        setDistricts([]);
      }
    }
  }, [state]);

  // Previous Employment Information
  const [employmentEntries, setEmploymentEntries] = useState([
    {
      employerName: "",
      startDate: "",
      endDate: "",
      experienceDuration: "",
      designation: "",
      department: "",
      city: "",
      district: "",
      state: "",
      industry: "",
      role: "",
      reasonLeaving: "",
      districts: [], // Add this to store districts for each entry
    },
  ]);

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const updatedEntries = [...employmentEntries];
    updatedEntries[index][name] = value;

    // If state changes, also reset the district and districts for that entry
    if (name === "state") {
      const selectedState = StateData.states.find((s) => s.state === value);
      if (selectedState) {
        updatedEntries[index].districts = selectedState.districts; // Populate districts
        updatedEntries[index].district = ""; // Reset district
      } else {
        updatedEntries[index].districts = []; // Clear districts if state is invalid
      }
    }

    setEmploymentEntries(updatedEntries);
  };
  const handleAddExperience = () => {
    setEmploymentEntries([
      ...employmentEntries,
      {
        employerName: "",
        startDate: "",
        endDate: "",
        experienceDuration: "",
        designation: "",
        department: "",
        city: "",
        district: "",
        state: "",
        industry: "",
        role: "",
        reasonLeaving: "",
      },
    ]);
  };

  useEffect(() => {
    if (employerState) {
      const selectedState = StateData.states.find(
        (s) => s.state === employerState
      );
      if (selectedState) {
        setEmployerDistricts(selectedState.districts);
        setEmployerDistrict(""); // Reset district when state changes
      } else {
        setEmployerDistricts([]);
      }
    }
  }, [employerState]);

  // Language Skills Section

  const [languageSkills, setLanguageSkills] = useState([
    {
      language: "",
      speakingProficiency: "",
      readingProficiency: "",
      writingProficiency: "",
    },
  ]);

  const handleLanguageChange = (index, event) => {
    const values = [...languageSkills];
    values[index][event.target.name] = event.target.value;
    setLanguageSkills(values);
  };

  const handleAddLanguage = () => {
    setLanguageSkills([
      ...languageSkills,
      {
        language: "",
        speakingProficiency: "",
        readingProficiency: "",
        writingProficiency: "",
      },
    ]);
  };

  // Fetch jobs from API
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/active/jobs`)
      .then((response) => {
        setJobCards(response.data.data); // Store the job data from API
        setLoading(false);
      })
      .catch((err) => {
        setError("Failed to load jobs"); // Handle error
        setLoading(false);
      });
  }, []);

  const scrollToSection = () => {
    const section = document.getElementById("current-openings");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Handle resume file change
  const handleResumeChange = (e) => {
    setResume(e.target.files[0]);
  };

  const handleJobClick = (job) => {
    setSelectedJob(job);
    if (applicationFormRef.current) {
      applicationFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoader(true);

    if (!selectedJob) {
      alert("Please select a job");
      return;
    }

    const formData = new FormData();

    // Job and personal information
    formData.append("job_id", selectedJob.id);
    formData.append("name", name);
    formData.append("fathersName", fathersName);
    formData.append("maritalStatus", maritalStatus);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("gender", gender);
    formData.append("bloodGroup", bloodGroup);
    formData.append("dob", dob);
    formData.append("homeContact", homeContact);
    formData.append("address", address);
    formData.append("state", state);
    formData.append("district", district);
    formData.append("city", city);
    formData.append("pinCode", pinCode);
    formData.append("country", country);

    // Resume file
    if (resume) {
      formData.append("resume_path", resume);
    }

    // Employment history
    employmentEntries.forEach((entry, index) => {
      Object.keys(entry).forEach((key) => {
        formData.append(`previous_employment[${index}][${key}]`, entry[key]);
      });
    });

    // Education information
    educationFields.forEach((field, index) => {
      Object.keys(field).forEach((key) => {
        formData.append(`education_information[${index}][${key}]`, field[key]);
      });
    });

    // Language skills
    languageSkills.forEach((language, index) => {
      Object.keys(language).forEach((key) => {
        formData.append(`languages[${index}][${key}]`, language[key]);
      });
    });

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/job/response`, formData)
      .then((response) => {
        toast.success("Application submitted successfully!");
        setSubmitLoader(false);
        // console.log(response);

        // Reset form fields here if necessary

        setName("");
        setFathersName("");
        setMaritalStatus("");
        setPhone("");
        setEmail("");
        setGender("");
        setBloodGroup("");
        setDob("");
        setHomeContact("");
        setAddress("");
        setState("");
        setDistrict("");
        setCity("");
        setPinCode("");
        setCountry("");
        setResume(null);
        setEmploymentEntries([{ /* initial state for employment */ }]);
        setEducationFields([{ /* initial state for education */ }]);
        setLanguageSkills([{ /* initial state for language */ }]);
        setSelectedJob(null);
      })
      .catch((err) => {
        toast.error("Failed to submit application");
        setSubmitLoader(false);
        console.error(err);
      });
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <PageHeader
        title={"Careers"}
        description={"Look at opportunities in our company"}
        crums={[
          {
            label: "Careers",
            path: "/careers",
          },
        ]}
      />

      <section>
        <div className="container py-20 space-y-16">
          <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-10 items-center">
            <figure>
              <img
                src="/assets/Careers/img-1.jpg"
                alt="img-1"
                className="w-full h-auto shadow-lg p-5"
              />
            </figure>

            <figure className="space-y-5">
              <h1 className="text-4xl font-bold">
                Join Our Journey of Innovation
              </h1>

              <div className="space-y-4">
                <h2 className="text-2xl font-semibold">
                  Company Culture & Environment:
                </h2>

                <p className="text-xl font-semibold">Welcome to HomeGurus</p>

                <p>
                  At HomeGurus, we believe that our most valuable asset is our
                  people. Our culture is built on a foundation of collaboration,
                  innovation, and mutual respect, ensuring that every team
                  member feels valued and empowered to reach their full
                  potential.
                </p>

                <p>
                  A Dynamic and Supportive Environment at our Workplace where
                  you will find Growth Opportunities, Inclusive Work Culture,
                  Learning and Development, Work-Life Balance, and a strong
                  focus on Employee Well-being.
                </p>

                <p>
                  When you join HomeGurus, you become part of a vibrant
                  community that values your contributions and supports your
                  professional journey. Our commitment to fostering a positive
                  and engaging workplace makes HomeGurus not just a place to
                  work, but a place to grow and thrive. If you are passionate,
                  driven, and ready to make a difference, we invite you to
                  explore career opportunities with us. Together, we can achieve
                  great things and shape the future of our industry.
                </p>

                <h2 className="text-xl font-medium">
                  Are You Interested in joining our team?
                </h2>
                <p>
                  <a
                    className="text-yellow-500 font-semibold"
                    onClick={scrollToSection}
                  >
                    CLICK HERE
                  </a>{" "}
                  For Current Openings and find the right opportunity for you at
                  HomeGurus.
                </p>
              </div>
            </figure>
          </div>

          <section id="current-openings" className="space-y-10">
            <h1 className="text-4xl font-bold text-center">Current Openings</h1>

            {/* Loading and Error Handling */}
            {loading && (
              <div className="text-center">
                <div className="spinner"></div>
              </div>
            )}
            {error && <p className="text-center text-red-500">{error}</p>}

            {/* Job Cards */}
            <div className="grid lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-10">
              {!loading &&
                !error &&
                jobCards.length > 0 &&
                jobCards.map((job) => (
                  <div
                    key={job.id}
                    className="cursor-pointer p-6 bg-white shadow-lg rounded-lg hover:bg-gray-100 transition relative"
                  >
                    <h2 className="text-2xl font-semibold">{job.title}</h2>

                    {/* Location and Positions */}
                    <div className="flex gap-4 mt-2 md:flex-row md:gap-4">
                      <p className="text-lg text-gray-700 break-words flex items-center md:text-md">
                        <FaLocationDot className="mr-1" />
                        Location: {job.location}
                      </p>
                      <p className="text-lg text-gray-700 break-words flex items-center md:text-md">
                        <MdPeople size={22} className="mr-1" />
                        Positions: {job.number_of_positions}
                      </p>
                    </div>

                    {/* Dates and Status */}
                    <div className="flex flex-col gap-4 mt-3 md:flex-row md:gap-4">
                      <p className="text-sm text-gray-500 md:text-sm md:text-gray-700">
                        <strong>Opening Date:</strong>{" "}
                        {new Date(job.opening_date).toLocaleDateString()}
                      </p>
                      <p className="text-sm text-gray-500 md:text-sm md:text-gray-700">
                        <strong>Closing Date:</strong>{" "}
                        {new Date(job.closing_date).toLocaleDateString()}
                      </p>
                      <p className="text-sm text-gray-500 md:text-sm md:text-gray-700">
                        <strong>Status:</strong>{" "}
                        {job.status === 1 ? "Active" : "Inactive"}
                      </p>
                      <p className="text-sm text-gray-500 md:text-sm md:text-gray-700">
                        <strong>Created At:</strong>{" "}
                        {new Date(job.created_at).toLocaleDateString()}
                      </p>
                    </div>

                    {/* Description */}
                    <p className="mt-2 text-gray-600 break-words flex items-start">
                      <MdOutlineDescription size={22} className="mr-1" />
                      <strong>Description: </strong>
                      {job.description}
                    </p>

                    {/* Requirements */}
                    <p className="mt-2 text-gray-600 break-words flex items-start">
                      <RiFileList2Line size={22} className="mr-1" />
                      <strong>Requirements: </strong>
                      {job.requirements}
                    </p>

                    {/* Apply Now Button on small screens */}
                    <div className="md:mt-8 mt-4">
                      <button
                        onClick={() => handleJobClick(job)}
                        className="btn-primary-md w-full md:absolute md:bottom-6 md:right-6 md:w-auto"
                      >
                        Apply Now
                      </button>
                    </div>
                  </div>
                ))}
            </div>
            {/* Job Application Form */}
            {selectedJob && (
              <div ref={applicationFormRef} className="space-y-5">
                <div className="text-center">
                  <h1 className="font-semibold text-xl">
                    Apply for {selectedJob.title}
                  </h1>
                </div>
                <div className="lg:w-8/12 md:w-full sm:w-full mx-auto space-y-10">
                  <form className="space-y-6" onSubmit={handleSubmit}>
                    {/* Personal Profile Information Section */}
                    <h2 className="text-xl font-semibold">
                      Personal Profile Information
                    </h2>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-6">
                      <div className="input-group">
                        <label htmlFor="name" className="input-label">
                          Your Name <em>*</em>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Your Name"
                          className="input-box-md"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>

                      <div className="input-group">
                        <label htmlFor="fathersName" className="input-label">
                          Father's Name <em>*</em>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Father's Name"
                          className="input-box-md"
                          value={fathersName}
                          onChange={(e) => setFathersName(e.target.value)}
                          required
                        />
                      </div>

                      <div className="input-group">
                        <label htmlFor="maritalStatus" className="input-label">
                          Marital Status <em>*</em>
                        </label>
                        <select
                          value={maritalStatus}
                          onChange={(e) => setMaritalStatus(e.target.value)}
                          className="input-box-md"
                          required
                        >
                          <option value="">Select</option>
                          <option value="Married">Married</option>
                          <option value="Unmarried">Unmarried</option>
                        </select>
                      </div>

                      <div className="input-group">
                        <label htmlFor="phone" className="input-label">
                          Mobile Number <em>*</em>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Mobile Number"
                          name="phone"
                          className="input-box-md"
                          value={phone}
                          onChange={(e) => {
                            const newValue = e.target.value.slice(0, 10);
                            setPhone(newValue);
                          }}
                          maxLength="10"
                          required
                        />
                      </div>

                      <div className="input-group">
                        <label htmlFor="email" className="input-label">
                          Email <em>*</em>
                        </label>
                        <input
                          type="email"
                          placeholder="Enter Email Address"
                          className="input-box-md"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>

                      <div className="input-group">
                        <label htmlFor="gender" className="input-label">
                          Gender <em>*</em>
                        </label>
                        <select
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                          className="input-box-md"
                          required
                        >
                          <option value="">Select</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Transgender">Transgender</option>
                        </select>
                      </div>

                      <div className="input-group">
                        <label htmlFor="bloodGroup" className="input-label">
                          Blood Group <em>*</em>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Blood Group"
                          className="input-box-md"
                          value={bloodGroup}
                          onChange={(e) => setBloodGroup(e.target.value)}
                          required
                        />
                      </div>

                      <div className="input-group">
                        <label htmlFor="dob" className="input-label">
                          Date of Birth <em>*</em>
                        </label>
                        <input
                          type="date"
                          className="input-box-md"
                          value={dob}
                          onChange={(e) => setDob(e.target.value)}
                          required
                        />
                      </div>

                      <div className="input-group">
                        <label htmlFor="homeContact" className="input-label">
                          Home Contact Number <em>*</em>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Home Contact Number"
                          className="input-box-md"
                          value={homeContact}
                          onChange={(e) => setHomeContact(e.target.value)}
                        />
                      </div>

                      <div className="input-group">
                        <label htmlFor="address" className="input-label">
                          Address <em>*</em>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Address"
                          className="input-box-md"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          required
                        />
                      </div>

                      <div className="input-group">
                        <label htmlFor="state" className="input-label">
                          State <em>*</em>
                        </label>
                        <select
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          className="input-box-md"
                          required
                        >
                          <option value="">Select State</option>
                          {StateData.states.map((s, index) => (
                            <option key={index} value={s.state}>
                              {s.state}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* District Dropdown */}
                      <div className="input-group">
                        <label htmlFor="district" className="input-label">
                          District <em>*</em>
                        </label>
                        <select
                          value={district}
                          onChange={(e) => setDistrict(e.target.value)}
                          className="input-box-md"
                          required
                          disabled={!state} // Disable district dropdown until a state is selected
                        >
                          <option value="">Select District</option>
                          {districts.map((d, index) => (
                            <option key={index} value={d}>
                              {d}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="input-group">
                        <label htmlFor="city" className="input-label">
                          City <em>*</em>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter City"
                          className="input-box-md"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          required
                        />
                      </div>

                      <div className="input-group">
                        <label htmlFor="pinCode" className="input-label">
                          Pin Code <em>*</em>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Pin Code"
                          className="input-box-md"
                          value={pinCode}
                          onChange={(e) => setPinCode(e.target.value)}
                          required
                        />
                      </div>

                      <div className="input-group">
                        <label htmlFor="country" className="input-label">
                          Country <em>*</em>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Country"
                          className="input-box-md"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <Divider variant="middle" flexItem />

                    {/* Previous Employment Information Section */}
                    <div>
                      <h2 className="text-xl font-semibold mb-2">
                        Previous Employment Information
                      </h2>
                      <div className="input-group">
                        <label className="flex items-center">
                          <input
                            type="checkbox"
                            checked={isFresher}
                            onChange={(e) => setIsFresher(e.target.checked)}
                            className="mr-2"
                          />
                          Fresher<em className="text-red-500">*</em>
                        </label>
                      </div>

                      {!isFresher && (
                        <>
                          {employmentEntries.map((entry, index) => (
                            <div
                              key={index}
                              
                            >
                              <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-6">
                              <div className="input-group mt-2">
                                <label className="input-label">
                                  Employer Name<em>*</em>
                                </label>
                                <input
                                  type="text"
                                  name="employerName"
                                  placeholder="Enter Employer Name"
                                  className="input-box-md"
                                  value={entry.employerName}
                                  onChange={(e) => handleChange(index, e)}
                                  required
                                />
                              </div>

                              <div className="input-group">
                                <label className="input-label">
                                  Start Date<em>*</em>
                                </label>
                                <input
                                  type="date"
                                  name="startDate"
                                  className="input-box-md"
                                  value={entry.startDate}
                                  onChange={(e) => handleChange(index, e)}
                                  required
                                />
                              </div>

                              <div className="input-group">
                                <label className="input-label">
                                  End Date<em>*</em>
                                </label>
                                <input
                                  type="date"
                                  name="endDate"
                                  className="input-box-md"
                                  value={entry.endDate}
                                  onChange={(e) => handleChange(index, e)}
                                  required
                                />
                              </div>

                              <div className="input-group">
                                <label className="input-label">
                                  Experience Duration<em>*</em>
                                </label>
                                <input
                                  type="text"
                                  name="experienceDuration"
                                  placeholder="Enter Duration"
                                  className="input-box-md"
                                  value={entry.experienceDuration}
                                  onChange={(e) => handleChange(index, e)}
                                  required
                                />
                              </div>

                              <div className="input-group">
                                <label className="input-label">
                                  Designation
                                </label>
                                <input
                                  type="text"
                                  name="designation"
                                  placeholder="Enter Designation"
                                  className="input-box-md"
                                  value={entry.designation}
                                  onChange={(e) => handleChange(index, e)}
                                />
                              </div>

                              <div className="input-group">
                                <label className="input-label">
                                  Department
                                </label>
                                <input
                                  type="text"
                                  name="department"
                                  placeholder="Enter Department"
                                  className="input-box-md"
                                  value={entry.department}
                                  onChange={(e) => handleChange(index, e)}
                                />
                              </div>

                              {/* State Selection */}
                              <div className="input-group">
                                <label className="input-label">State</label>
                                <select
                                  value={entry.state}
                                  onChange={(e) => handleChange(index, e)}
                                  name="state"
                                  className="input-box-md"
                                >
                                  <option value="">Select State</option>
                                  {StateData.states.map((s, index) => (
                                    <option key={index} value={s.state}>
                                      {s.state}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              {/* District Selection */}
                              <div className="input-group">
                                <label className="input-label">District</label>
                                <select
                                  value={entry.district}
                                  onChange={(e) => handleChange(index, e)}
                                  name="district"
                                  className="input-box-md"
                                  disabled={!entry.state} // Disable until state is selected
                                >
                                  <option value="">Select District</option>
                                  {(entry.districts || []).map(
                                    (
                                      d,
                                      idx // Safeguard against undefined
                                    ) => (
                                      <option key={idx} value={d}>
                                        {d}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>

                              {/* City Input */}
                              <div className="input-group">
                                <label className="input-label">City</label>
                                <input
                                  type="text"
                                  name="city"
                                  placeholder="Enter City"
                                  className="input-box-md"
                                  value={entry.city}
                                  onChange={(e) => handleChange(index, e)}
                                />
                              </div>

                              <div className="input-group">
                                <label className="input-label">Industry</label>
                                <input
                                  type="text"
                                  name="industry"
                                  placeholder="Enter Industry"
                                  className="input-box-md"
                                  value={entry.industry}
                                  onChange={(e) => handleChange(index, e)}
                                />
                              </div>

                              <div className="input-group">
                                <label className="input-label">Role</label>
                                <input
                                  type="text"
                                  name="role"
                                  placeholder="Enter Role"
                                  className="input-box-md"
                                  value={entry.role}
                                  onChange={(e) => handleChange(index, e)}
                                />
                              </div>

                              <div className="input-group">
                                <label className="input-label">
                                  Reason for Leaving
                                </label>
                                <textarea
                                  name="reasonLeaving"
                                  placeholder="Enter Reason for Leaving"
                                  className="input-box-md"
                                  value={entry.reasonLeaving}
                                  onChange={(e) => handleChange(index, e)}
                                />
                              </div>
                              </div>
                              {index < employmentEntries.length - 1 && (
                <Divider sx={{ my: 2 }} /> // Adds vertical margin
              )}
                            </div>
                          ))}

                          {/* Add More Experience Button */}
                          <div className="mt-2">
                            <button
                              type="button"
                              className="btn-secondary-md"
                              onClick={handleAddExperience}
                            >
                              + Add More Experience
                            </button>
                          </div>
                        </>
                      )}
                    </div>

                    <Divider className="mb-2 " variant="middle" flexItem />

                    <div>
                      <h2 className="text-xl font-semibold mb-4">
                        Education Information
                      </h2>
                      {educationFields.map((education, index) => (
                        <div key={index}>
                          <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-6 mb-4">
                            <div className="input-group">
                              <label
                                htmlFor={`instituteName-${index}`}
                                className="input-label"
                              >
                                Institute/Board/University Name
                              </label>
                              <input
                                type="text"
                                name="instituteName"
                                placeholder="Enter Institute Name"
                                className="input-box-md"
                                value={education.instituteName}
                                onChange={(e) =>
                                  handleEducationChange(index, e)
                                }
                              />
                            </div>
                            <div className="input-group">
                              <label
                                htmlFor={`educationLevel-${index}`}
                                className="input-label"
                              >
                                Education Level
                              </label>
                              <select
                                name="educationLevel"
                                value={education.educationLevel}
                                onChange={(e) =>
                                  handleEducationChange(index, e)
                                }
                                className="input-box-md"
                              >
                                <option value="">Select</option>
                                <option value="10th">10th</option>
                                <option value="12th">12th</option>
                                <option value="Diploma">Diploma</option>
                                <option value="Bachelors">Bachelors</option>
                                <option value="Masters">Masters</option>
                                <option value="Certificate">
                                  Certificate Courses
                                </option>
                              </select>
                            </div>
                            <div className="input-group">
                              <label
                                htmlFor={`courseName-${index}`}
                                className="input-label"
                              >
                                Course Name
                              </label>
                              <input
                                type="text"
                                name="courseName"
                                placeholder="Enter Course Name"
                                className="input-box-md"
                                value={education.courseName}
                                onChange={(e) =>
                                  handleEducationChange(index, e)
                                }
                              />
                            </div>
                            <div className="input-group">
                              <label
                                htmlFor={`specialization-${index}`}
                                className="input-label"
                              >
                                Specialization
                              </label>
                              <input
                                type="text"
                                name="specialization"
                                placeholder="Enter Specialization"
                                className="input-box-md"
                                value={education.specialization}
                                onChange={(e) =>
                                  handleEducationChange(index, e)
                                }
                              />
                            </div>
                            <div className="input-group">
                              <label
                                htmlFor={`duration-${index}`}
                                className="input-label"
                              >
                                Duration Of Course
                              </label>
                              <input
                                type="text"
                                name="duration"
                                placeholder="Enter Duration"
                                className="input-box-md"
                                value={education.duration}
                                onChange={(e) =>
                                  handleEducationChange(index, e)
                                }
                              />
                            </div>
                            <div className="input-group">
                              <label
                                htmlFor={`yearOfPassing-${index}`}
                                className="input-label"
                              >
                                Year Of Passing
                              </label>
                              <input
                                type="text"
                                name="yearOfPassing"
                                placeholder="Enter Year Of Passing"
                                className="input-box-md"
                                value={education.yearOfPassing}
                                onChange={(e) =>
                                  handleEducationChange(index, e)
                                }
                              />
                            </div>
                            <div className="input-group">
                              <label
                                htmlFor={`grade-${index}`}
                                className="input-label"
                              >
                                Final Grade / Percentage
                              </label>
                              <input
                                type="text"
                                name="grade"
                                placeholder="Enter Grade / Percentage"
                                className="input-box-md"
                                value={education.grade}
                                onChange={(e) =>
                                  handleEducationChange(index, e)
                                }
                              />
                            </div>
                          </div>
                          {/* Add a Divider between inputs, except for the last one */}
                          {index < educationFields.length - 1 && (
                            <Divider sx={{ my: 2 }} /> // This adds vertical margin (my) of 2 spacing units
                          )}{" "}
                        </div>
                      ))}
                      <div className="mt-2">
                        <button
                          type="button"
                          className="btn-secondary-md"
                          onClick={handleAddEducation}
                        >
                          + Add more education
                        </button>
                      </div>
                    </div>

                    <Divider className="mb-2 " variant="middle" flexItem />

                    <h2 className="text-xl font-semibold">Language Skills</h2>
                    {languageSkills.map((field, index) => (
                      <div key={index}>
                        <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-6">
                          <div className="input-group">
                            <label
                              htmlFor={`language-${index}`}
                              className="input-label"
                            >
                              Language
                            </label>
                            <input
                              type="text"
                              name="language"
                              placeholder="Enter Language"
                              className="input-box-md"
                              value={field.language}
                              onChange={(e) => handleLanguageChange(index, e)}
                            />
                          </div>

                          <div className="input-group">
                            <label
                              htmlFor={`speakingProficiency-${index}`}
                              className="input-label"
                            >
                              Speaking Proficiency
                            </label>
                            <select
                              name="speakingProficiency"
                              value={field.speakingProficiency}
                              onChange={(e) => handleLanguageChange(index, e)}
                              className="input-box-md"
                            >
                              <option value="">Select</option>
                              <option value="Elementary">Elementary</option>
                              <option value="Intermediate">Intermediate</option>
                              <option value="Expert">Expert</option>
                              <option value="Nil">Nil</option>
                            </select>
                          </div>

                          <div className="input-group">
                            <label
                              htmlFor={`readingProficiency-${index}`}
                              className="input-label"
                            >
                              Reading Proficiency
                            </label>
                            <select
                              name="readingProficiency"
                              value={field.readingProficiency}
                              onChange={(e) => handleLanguageChange(index, e)}
                              className="input-box-md"
                            >
                              <option value="">Select</option>
                              <option value="Elementary">Elementary</option>
                              <option value="Intermediate">Intermediate</option>
                              <option value="Expert">Expert</option>
                              <option value="Nil">Nil</option>
                            </select>
                          </div>

                          <div className="input-group">
                            <label
                              htmlFor={`writingProficiency-${index}`}
                              className="input-label"
                            >
                              Writing Proficiency
                            </label>
                            <select
                              name="writingProficiency"
                              value={field.writingProficiency}
                              onChange={(e) => handleLanguageChange(index, e)}
                              className="input-box-md"
                            >
                              <option value="">Select</option>
                              <option value="Elementary">Elementary</option>
                              <option value="Intermediate">Intermediate</option>
                              <option value="Expert">Expert</option>
                              <option value="Nil">Nil</option>
                            </select>
                          </div>
                        </div>
                        {/* Add a Divider between inputs, except for the last one */}
                        {index < languageSkills.length - 1 && (
                          <Divider className="pt-3" />
                        )}
                      </div>
                    ))}

                    <button
                      type="button"
                      onClick={handleAddLanguage}
                      className="btn-add"
                    >
                      + Add More Language
                    </button>

                    <Divider className="mb-2 " variant="middle" flexItem />

                    <h2 className="text-xl font-semibold">My Documents</h2>

                    <div className="input-group">
                      <label htmlFor="resume" className="input-label">
                        Resume/CV <em>*</em>
                      </label>
                      <input
                        type="file"
                        id="resume"
                        accept=".pdf,.doc,.docx"
                        onChange={handleResumeChange}
                        required
                      />
                    </div>

                    <div className="lg:col-span-3 md:col-span-3 sm:col-span-1">
                      <button
                        type="submit"
                        className="btn-primary-md w-full"
                        disabled={submitLoader}
                      >
                        {submitLoader
                          ? "Submitting Application..."
                          : "Submit Application"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </section>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Careers;