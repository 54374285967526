import React, { useEffect, useState } from 'react';
import PageHeader from '../../components/Common/PageHeader/PageHeader';
import ServiceCard from '../../components/Sections/Services/ServiceCard/ServiceCard';
import axios from 'axios';

const Services = () => {

  const [services, setServices] = useState([]);

  useEffect(() => {
    try {
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/service`)
      .then((response) => {
        setServices(response.data.data);
        // console.log(response);

      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <React.Fragment>
      <PageHeader title={"Services"} description={"What Services we Provide ?"} crums={[{
        label: "Services",
        path: "/services"
      }]} />
      <section>
        <div className='container py-16 space-y-10'>
          
          <div className="grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 gap-10">
            {services.map((item, index) => ( <ServiceCard data={item} key={index} /> ))}
          </div>

        </div>
      </section>
    </React.Fragment>
  )
}

export default Services;