import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';

const Setting = () => {

  const auth = useSelector((state) => state.AuthReducer);
  const genders = [
    {
      label: 'Male',
      value: 'MALE'
    },
    {
      label: 'Female',
      value: 'FEMALE'
    },
    {
      label: 'Other',
      value: 'OTHER'
    }
  ];

  const [inputFields, setInputFields] = useState({
    name: '',
    email: '',
    phone: '',
    date_of_birth: '',
    gender: '',
    profession: '',
    home: '',
    street: '',
    city: '',
    postal_code: '',
    state: '',
    gst_number: '',
  });

  useEffect(() => {
    if (auth) {
      setInputFields({
        name: auth.name,
        email: auth.email,
        phone: auth.phone,
        date_of_birth: auth.date_of_birth,
        gender: auth.gender,
        profession: auth.profession,
        home: auth.home,
        street: auth.street,
        city: auth.city,
        postal_code: auth.postal_code,
        state: auth.state,
        gst_number: auth.gst_number
      });
    }
  }, [auth]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputFields({ ...inputFields, [name]: value });
  }

  const handleProfileChange = (event) => {
    setInputFields({ ...inputFields, ['profile']: event.target.files[0] });
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user`, inputFields, {
        headers: {
          "Content-Type": 'multipart/form-data',
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
        }
      });
      if (response.data.status) {
        toast.success(response.data.message);
      }
      else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('An error occured');
    }
  }

  if (!auth) {
    return "Loading";
  }

  return (
    <React.Fragment>
      <form onSubmit={(event) => handleFormSubmit(event)}>
        <figure className='border shadow-lg'>
          <div className='px-5 py-4 space-y-1 border-b'>
            <h1 className='text-base font-medium'>Account Settings</h1>
            <p className='text-xs text-gray-500'>Manage your account information</p>
          </div>
          <div className='p-5 space-y-1'>
            <div className='grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5'>

              <div className='lg:col-span-3 md:col-span-2 sm:col-span-1'>
                <h1 className='font-medium'>General Information</h1>
              </div>

              {/* Name */}
              <div className="input-group">
                <label htmlFor="name" className='input-label'>Your Name <em>*</em></label>
                <input type="text" name='name' className='input-box-md' placeholder='Enter Name' value={inputFields.name} required minLength={5} maxLength={100} onChange={(event) => handleInputChange(event)} />
              </div>

              {/* Profession */}
              <div className="input-group">
                <label htmlFor="profession" className='input-label'>Profession <em>*</em></label>
                <input type="text" name='profession' className='input-box-md' placeholder='Enter Profession' value={inputFields.profession} required minLength={5} maxLength={100} onChange={(event) => handleInputChange(event)} />
              </div>

              {/* Date of Birth */}
              <div className="input-group">
                <label htmlFor="date_of_birth" className='input-label'>Date of Birth <span>(Optional)</span></label>
                <input type="date" name='date_of_birth' className='input-box-md' value={inputFields.date_of_birth} onChange={(event) => handleInputChange(event)} />
              </div>

              {/* GST Number */}
              <div className="input-group">
                <label htmlFor="gst_number" className='input-label'>GST Number <span>(Optional)</span></label>
                <input type="text" name='gst_number' pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$" className='input-box-md' placeholder='Enter GST Number' value={inputFields.gst_number} minLength={5} maxLength={50} onChange={(event) => handleInputChange(event)} />
              </div>

              {/* Gender */}
              <div className="input-group">
                <label htmlFor="gender" className='input-label'>Gender <span>(Optional)</span></label>
                <div className='flex gap-3 mt-3'>
                  {genders.map(({ label, value }) => (
                    <div className='input-radio' key={value}>
                      <input type="radio" name='gender' id={`gender_${value}`} value={value} checked={value == inputFields.gender} onChange={(event) => handleInputChange(event)} />
                      <label htmlFor={`gender_${value}`}>{label}</label>
                    </div>
                  ))}
                </div>
              </div>
                    
              <div className='lg:col-span-3 md:col-span-2 sm:col-span-1 pt-3'>
                <h1 className='font-medium'>Address Information</h1>
              </div>

              {/* Home */}
              <div className="input-group">
                <label htmlFor="home" className='input-label'>Home / Flat <span>(Optional)</span></label>
                <input type="text" name='home' className='input-box-md' placeholder='Enter Home / Flat' value={inputFields.home} minLength={5} maxLength={250} onChange={(event) => handleInputChange(event)} />
              </div>

              {/* Street */}
              <div className="input-group">
                <label htmlFor="street" className='input-label'>Street / Area <span>(Optional)</span></label>
                <input type="text" name='street' className='input-box-md' placeholder='Enter Street / Area' value={inputFields.street} minLength={5} maxLength={250} onChange={(event) => handleInputChange(event)} />
              </div>

              {/* City */}
              <div className="input-group">
                <label htmlFor="city" className='input-label'>City <em>*</em></label>
                <input type="text" name='city' className='input-box-md' placeholder='Enter City' value={inputFields.city} required minLength={2} maxLength={100} onChange={(event) => handleInputChange(event)} />
              </div>

              {/* Postal Code */}
              <div className="input-group">
                <label htmlFor="postal_code" className='input-label'>Postal Code <em>*</em></label>
                <input type="text" name='postal_code' className='input-box-md' placeholder='Enter Postal Code' value={inputFields.postal_code} required minLength={6} maxLength={6} pattern='[0-9]{6}' onChange={(event) => handleInputChange(event)} />
              </div>

              {/* State */}
              <div className="input-group">
                <label htmlFor="state" className='input-label'>State <em>*</em></label>
                <input type="text" name='state' className='input-box-md' placeholder='Enter State' value={inputFields.state} required minLength={5} maxLength={50} onChange={(event) => handleInputChange(event)} />
              </div>

            </div>
          </div>
          <div className='p-5 space-y-1 border-t'>
            <button type='submit' className='btn-primary-sm md:w-fit sm:w-full'>Save Changes</button>
          </div>
        </figure>
      </form>
    </React.Fragment>
  )
}

export default Setting