import React from "react";
import { TbMail, TbPhone, TbEdit } from "react-icons/tb";
import { FaLocationDot } from "react-icons/fa6";

import { MdCalendarMonth, MdVerified } from "react-icons/md";
import { RiSuitcaseLine } from "react-icons/ri";
import { IoPerson } from "react-icons/io5";
import { RiBillLine } from "react-icons/ri";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Account = () => {
  const auth = useSelector((state) => state.AuthReducer);
  console.log(auth);
  if (!auth) {
    return "Loading";
  }

  // Extract the first letter of the user's name as fallback
  const userInitial = auth.name ? auth.name.charAt(0).toUpperCase() : "";

  return (
    <React.Fragment>
      <figure className="bg-white border shadow-lg">
        <div
          className="bg-ascent md:px-20 sm:px-7 relative py-20 overflow-visible"
          style={{ backgroundImage: "url('/assets/Dashboard/account-bg.svg')" }}
        >
          {(
            // Fallback avatar with first letter of the user's name
            <div className="h-[100px] w-[100px] rounded-full bg-gray-300 border-[6px] border-white absolute z-20 -bottom-1/3 flex items-center justify-center text-gray-700 text-4xl font-semibold">
              {userInitial}
            </div>
          )}
        </div>
        <div className="md:px-20 sm:px-7 pt-20 lg:pb-16 md:pb-10 sm:pb-8">
          <div className="space-y-2">
            {auth.name && <h1 className="text-xl font-medium">{auth.name}</h1>}
            {auth.profession && (
              <p className="text-gray-500 text-xs flex items-center">
                <RiSuitcaseLine size={14} className="mr-1 mb-[1px]" />
                {auth.profession}
              </p>
            )}
            {auth.date_of_birth && (
              <p className="text-gray-500 text-xs flex items-center">
                <MdCalendarMonth size={14} className="mr-1 mb-[1px]" />
                {auth.date_of_birth}
              </p>
            )}
            {auth.gender && (
              <p className="text-gray-500 text-xs flex items-center">
                <IoPerson size={14} className="mr-1 mb-[1px]" />
                {auth.gender}
              </p>
            )}
            {auth.gst_number && (
              <p className="text-gray-500 text-xs flex items-center">
                <RiBillLine size={14} className="mr-1 mb-[1px]" />
                GSTIN: {auth.gst_number} <MdVerified className="ml-1" size={14} color="green"/>
              </p>
            )}
            {auth.street && auth.city && auth.state && (
              <p className="text-gray-500 text-xs flex items-center">
                <FaLocationDot size={14} className="mr-1 mb-[1px]" />
                {auth.street}, {auth.city}, {auth.state}
              </p>
            )}
            {auth.email && (
              <p className="text-gray-500 text-xs flex items-center">
                <TbMail size={14} className="mr-1 mb-[1px]" />
                {auth.email}
              </p>
            )}
            {auth.phone && (
              <p className="text-gray-500 text-xs flex items-center">
                <TbPhone size={14} className="mr-1" /> +91-{auth.phone}
              </p>
            )}
          </div>
          <div className="mt-5">
            <Link to={"/dashboard/setting"}>
              <button className="btn-primary-sm flex items-center justify-center">
                <TbEdit size={16} className="mb-[1px] mr-1" /> Edit Information
              </button>
            </Link>
          </div>
        </div>
      </figure>
    </React.Fragment>
  );
};

export default Account;
