import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Lottie from "lottie-react";
import PageHeader from "../../components/Common/PageHeader/PageHeader";
import constructionAnimation from "../../lottie/construction.json"; // Adjust path as needed

const Gallery = () => {
  const [galleryData, setGalleryData] = useState(null);
  const [error, setError] = useState(false);
  const [videos, setVideos] = useState([]);

  const videoSectionRef = useRef(null); // Ref for video section
  const topRef = useRef(null); // Ref for top of the page

  useEffect(() => {
    const fetchGalleryData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/gallery/image`,
          { code: "homeguru" }
        );
        // console.log(response);
        const data = response.data.data;

        // Separate images and videos
        const imagesData = {};
        const videosArray = [];

        Object.keys(data).forEach((categoryKey) => {
          const mediaItems = data[categoryKey].media || [];

          const filteredImages = mediaItems.filter((item) => item.image_path);
          const filteredVideos = mediaItems.filter((item) => item.youtube_url);

          if (filteredImages.length > 0) {
            imagesData[categoryKey] = {
              ...data[categoryKey],
              media: filteredImages,
            };
          }

          videosArray.push(...filteredVideos);
        });

        setGalleryData(imagesData);
        setVideos(videosArray);
      } catch (error) {
        console.error("Error fetching gallery data:", error);
        setError(true);
      }
    };

    fetchGalleryData();
  }, []);

  const scrollToVideos = () => {
    videoSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  };

  if (error) {
    return (
      <div className="text-center">
        <Lottie
          animationData={constructionAnimation}
          loop={true}
          style={{ width: 300, height: 200, margin: "0 auto" }}
        />
        <p className="m-4">No Images to show</p>
      </div>
    );
  }

  if (!galleryData) {
    return (
      <div className="text-center m-20">
        <div className="spinner"></div>
      </div>
    );
  }

  const hasMediaItems = Object.keys(galleryData).some(
    (key) => galleryData[key].media.length > 0
  );

  return (
    <>
      <PageHeader
        title={"Gallery"}
        description={"See our projects come to life."}
        crums={[
          {
            label: "Gallery",
            path: "/gallery",
          },
        ]}
      />
      <section ref={topRef} className="container mx-auto py-10">
        <h1 className="text-center text-4xl font-bold mb-10">Gallery</h1>

             {/* Button to scroll to videos */}
             {videos.length > 0 && (
              <div className="text-center my-10">
                <button
                  onClick={scrollToVideos}
                  className="btn-primary-sm w-md"
                >
                 Watch Our Videos
                </button>
              </div>
            )}

        {!hasMediaItems ? (
          <div className="text-center">
            <Lottie
              animationData={constructionAnimation}
              loop={true}
              style={{ width: 300, height: 300, margin: "0 auto" }}
            />
            <p className="m-4">No Images to show</p>
          </div>
        ) : (
          <>
            {/* Display images category-wise */}
            {Object.keys(galleryData).map((categoryKey) => {
              const category = galleryData[categoryKey];
              const mediaItems = category.media || [];
              return (
                <div key={categoryKey} className="mb-10">
                  <div className="flex flex-col items-center mb-5">
                    <img
                      src="/assets/Home/portfolio/portfolio-icon.png"
                      alt="portfolio-icon"
                      className="h-[50px] w-auto mb-2"
                    />
                    {category.category && (
                      <h2 className="text-3xl font-semibold text-center">
                        {category.category.name}
                      </h2>
                    )}
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                    {mediaItems.map((item, index) => (
                      <div key={index} className="relative">
                        <img
                          src={item.image_path}
                          alt={`Gallery item ${index}`}
                          className="w-full h-72 object-cover rounded-md"
                        />
                        {item.description && (
                          <p className="mt-2 text-center">{item.description}</p>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}

            {/* Video section */}
            {videos.length > 0 && (
              <div ref={videoSectionRef} className="mt-10">
                <div className="flex flex-col items-center mb-5">
                  <img
                    src="/assets/Home/portfolio/portfolio-icon.png"
                    alt="portfolio-icon"
                    className="h-[50px] w-auto mb-2"
                  />
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                  {videos.map((video, index) => (
                    <div key={index} className="relative">
                      <iframe
                        width="100%"
                        height="315"
                        src={`https://www.youtube.com/embed/${new URL(
                          video.youtube_url
                        ).pathname
                          .split("/")
                          .pop()}`}
                        title={`Video ${index}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                      {video.description && (
                        <p className="mt-2 text-center">{video.description}</p>
                      )}
                    </div>
                  ))}
                </div>

                {/* Back to Top Button */}
                <div className="text-center mt-10">
                  <button onClick={scrollToTop} className="btn-primary-sm w-md">
                    Back to Top
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </section>
    </>
  );
};

export default Gallery;
