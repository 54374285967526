import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import ServiceCard from "./ServiceCard";
import axios from "axios";

const Services = () => {
  const [services, setServices] = useState([]);

  useState(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/service`
      );
      setServices(response.data.data);
    } catch (error) {
      // console.log(error);
    }
  }, []);

  return (
    <React.Fragment>
      <section className="bg-complement">
        <div className="container lg:py-20 sm:py-16 lg:space-y-20 sm:space-y-10">
          <div className="flex items-center justify-center text-center flex-col space-y-4">
            <img
              src="/assets/Home/portfolio/portfolio-icon.png"
              alt="portfolio-icon"
              className="h-[50px] w-auto pb-2"
            />
            <p className="font-bold lg:text-base sm:text-sm">OUR SERVICES</p>
            <h1 className="font-bold lg:text-4xl sm:text-3xl leading-relaxed">
              What Services we Provide ?
            </h1>
          </div>

          <div className="overflow-visible pb-32">
            <Swiper
              style={{
                "--swiper-pagination-color": "#fff",
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#FFEE02",
                "--swiper-navigation-sides-offset": "30px",
                "--swiper-pagination-bottom": "-140px",
                "--swiper-pagination-bullet-width": "10px",
                "--swiper-pagination-bullet-height": "10px",
                "--swiper-pagination-bullet-border-radius": "0px",
                "--swiper-pagination-bullet-horizontal-gap": "2px",
                "--swiper-pagination-bullet-inactive-opacity": "0.1",
              }}
              className="portfolio-slider !overflow-y-visible"
              loop={true}
              speed={1000}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
              grabCursor={true}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                640: {
                  spaceBetween: 50,
                  slidesPerView: 1,
                },
                768: {
                  spaceBetween: 50,
                  slidesPerView: 2,
                },
              }}
              modules={[Pagination, Autoplay]}
            >
              {services.map((item, index) => (
                <SwiperSlide key={index} className="overflow-visible">
                  <ServiceCard data={item} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Services;
