import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BiMessageSquareDetail } from "react-icons/bi";
import LoginModal from "../../Authentication/LoginModal/LoginModal";
import { useSelector } from "react-redux";
import axios from "axios";

const DesktopHeader = () => {
  const auth = useSelector((state) => state.AuthReducer);

  const [loginModalState, setLoginModalState] = useState(false);

  const [headerState, setHeaderSate] = useState("inactive");

  const [services, setServices] = useState([]);

  // useEffect(() => {
  //   window.addEventListener('scroll', function () {
  //     if (this.window.scrollY > 100) {
  //       setHeaderSate('active shadow-xl');
  //     }
  //     else {
  //       setHeaderSate('inactive');
  //     }
  //   });
  // }, []);

  useEffect(() => {
    try {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/service`)
        .then((response) => {
          setServices(response.data.data);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const location = useLocation();

  return (
    <React.Fragment>
      <header
        className={`fixed top-0 z-50 w-full lg:block md:block sm:hidden active shadow-xl`}
        id="desktop-header"
      >
        <div className="container py-3">
          <nav className="flex items-center justify-between">
            <div>
              <Link to={"/"}>
                <img
                  src="/assets/Logo/logo.png"
                  alt="logo"
                  className="h-[80px] w-auto"
                />
              </Link>
            </div>
            <div>
              <ul className="flex items-center justify-center space-x-5">
                <li className="py-4">
                  <Link to={"/"} className="header-nav-link">
                    Home
                  </Link>
                </li>
                <li className="py-4">
                  <Link to={"/about"} className="header-nav-link">
                    About us
                  </Link>
                </li>
                <li className="py-4 relative flex items-center justify-center group">
                  <Link
                    to={"/services"}
                    className="header-nav-link group-hover:text-ascent"
                  >
                    Services
                  </Link>
                  <div className="px-6 py-5 absolute bg-white shadow-[0px_15px_25px_rgba(0,0,0,0.5)] top-full hidden group-hover:block max-h-[400px] overflow-y-scroll">
                    <div className="space-y-3 flex flex-col whitespace-nowrap">
                      {services.map((item, index) => (
                        <Link
                          key={index}
                          to={`/service/${item.slug}`}
                          className="text-sm font-medium hover:text-yellow-600 transition duration-300 ease-in-out"
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </li>
                <li className="py-4">
                  <Link to={"/contact"} className="header-nav-link">
                    Contact us
                  </Link>
                </li>
                <li className="py-4">
                  <Link to={"/gallery"} className="header-nav-link">
                    Gallery
                  </Link>
                </li>
               
                {auth ? (
                  <li>
                    <Link to={"/dashboard/account"} className="header-nav-link">
                      My Account
                    </Link>
                  </li>
                ) : (
                  <li>
                    <a
                      onClick={() => setLoginModalState(true)}
                      className="header-nav-link cursor-pointer"
                    >
                      Login To Continue
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <div className="flex items-center space-x-3">
              <Link
                to={"/make-quote"}
                className="btn-primary-md flex space-x-2 items-center"
              >
                <span>Service Request</span>
                <BiMessageSquareDetail size={20} />
              </Link>
            </div>
          </nav>
        </div>
      </header>

      <LoginModal
        isOpen={loginModalState}
        closeModal={() => setLoginModalState(false)}
      />
    </React.Fragment>
  );
};

export default DesktopHeader;
