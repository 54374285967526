import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FaRegCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import ServiceFeedbackModal from "../../../components/Modal/ServiceFeedback/ServiceFeedbackModal";

const BookingCard = ({ booking }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDownloadInvoice = async () => {
    toast.info(`Downloading invoice `);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/wallet/invoice/${booking.uuid}`,
        { responseType: "blob" } // Ensures we get a binary file (blob) response
      );

      // Create a URL for the blob data
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `invoice_${booking.uuid}.pdf`); // Set the desired file name
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up by removing the link and revoking the blob URL
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the invoice:", error);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <figure className="pt-3">
        <div className="space-y-2">
          <h1 className="font-semibold text-base">{booking.service.name}</h1>

          {/* This Booked on date */}
          <p className="flex items-center space-x-1 text-sm">
            <FaRegCalendarAlt size={12} />
            <span>
              Booked on {moment(booking.created_at).format("Do MMMM YYYY")}
            </span>
          </p>
          {/* and this Total amount and paid amount  */}
          <div className="space-y-2">
            <p className="flex items-center space-x-1 text-sm">
              Total Amount = {process.env.REACT_APP_CURRENCY_SYMBOL}
              {parseInt(booking.total_amount).toLocaleString("en-IN")}
            </p>
            <p className="flex items-center space-x-1 text-sm">
              Paid = {process.env.REACT_APP_CURRENCY_SYMBOL}
              {parseInt(booking.total_paid).toLocaleString("en-IN")}
            </p>
          </div>

           {/* this badge for status */}
          <div className="capitalize">
            {booking.status === "PAYMENT_PENDING" && (
              <span className="bg-yellow-600 px-2 py-1 text-white rounded text-xs">
                {booking.status.replace("_", " ").toLowerCase()}
              </span>
            )}
            {booking.status === "CONFIRMED" && (
              <span className="bg-blue-600 px-2 py-1 text-white rounded text-xs">
                {booking.status.replace("_", " ").toLowerCase()}
              </span>
            )}
            {booking.status === "WORKING" && (
              <span className="bg-blue-600 px-2 py-1 text-white rounded text-xs">
                {booking.status.replace("_", " ").toLowerCase()}
              </span>
            )}
            {booking.status === "COMPLETED" && (
              <span className="bg-green-600 px-2 py-1 text-white rounded text-xs">
                {booking.status.replace("_", " ").toLowerCase()}
              </span>
            )}
            {booking.status === "CANCELLED" && (
              <span className="bg-red-600 px-2 py-1 text-white rounded text-xs">
                {booking.status.replace("_", " ").toLowerCase()}
              </span>
            )}
            {booking.status === "REQUEST_TO_CLOSE" && (
              <span className="bg-orange-500 px-2 py-1 text-white rounded text-xs">
                Request to Close
              </span>
            )}
          </div>

          <div className="py-2 flex flex-col sm:flex-row justify-between items-start">
  <div className="flex flex-col sm:flex-row">
    <Link
      to={`/dashboard/booking/${booking.uuid}`}
      className="btn-primary-sm"
    >
      View Booking Details
    </Link>

    {booking.status === "COMPLETED" && (
      <button
        onClick={handleDownloadInvoice}
        className="btn-primary-sm mt-2 sm:mt-0 sm:ml-2"
      >
        Download Invoice
      </button>
    )}
  </div>

  <div className="mt-1 sm:mt-0 sm:ml-4 w-full sm:w-auto">
  {booking.status === "COMPLETED" && (
    <div className="flex flex-col">
      <Link
        onClick={() => setIsModalOpen(true)}
        className="relative hidden md:block" // Hide on small screens, show on md and above
        style={{ color: "black" }} // Text color
      >
        Review
        <span
          className="absolute left-0 right-0 bottom-[-5px] h-[2px] bg-[rgba(255,238,2,255)]"
          style={{ marginTop: "3px" }} // Space between text and underline
        />
      </Link>
    </div>
  )}
</div>
</div>
<div>
{booking.status === "COMPLETED" && (
    <Link
      onClick={() => setIsModalOpen(true)}
      className="mt-2 sm:mt-2 md:hidden relative" // Show only on small devices
      style={{ color: "black" }} // Text color
    >
      Review
      <span
        className="absolute left-0 right-0 bottom-[-5px] h-[2px] bg-[rgba(255,238,2,255)]"
        style={{ marginTop: "3px" }} // Space between text and underline
      />
    </Link>
  )}

  <ServiceFeedbackModal
    isOpen={isModalOpen}
    onClose={() => setIsModalOpen(false)}
    serviceId={booking.service.id}
    userId={booking.service_quote.user_id} // Pass user_id to modal
  />
</div>

        </div>
      </figure>
    </React.Fragment>
  );
};

const Booking = () => {
  const [bookings, setBookings] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/service-quote-booking`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (response.data.status) {
        setBookings(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occurred");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <figure className="border shadow-lg">
        <div className="px-5 py-4 space-y-1 border-b">
          <h1 className="text-base font-medium">My Bookings</h1>
          <p className="text-xs text-gray-500">List of all your bookings</p>
        </div>
        <div className="p-5 pt-1 space-y-3 divide-y">
          {bookings.map((booking) => (
            <BookingCard key={booking.id} booking={booking} />
          ))}
        </div>
      </figure>
    </React.Fragment>
  );
};

export default Booking;
