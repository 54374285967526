import React from 'react';
import PageHeader from '../../components/Common/PageHeader/PageHeader';

const RefundPolicy = () => {
  return (
    <React.Fragment>
      <PageHeader title={"Cancellation & Refund Policy"} description={"Your Satisfaction is Our Priority"} crums={[{
        label: "Cancellation & Refund Policy",
        path: "/refund-policy"
      }]} />
      <section>
        <div className='container py-10 space-y-10'>

          <div>
            <h1 className='text-3xl font-bold'>Our Cancellation & Refund Policy</h1>
          </div>

          <article className='policies'>
            <p>
              At <strong>HomeGurus Constructive Solutions Private Limited</strong>, we are committed to providing a smooth and reliable service 
              experience for all our customers. With our platform, you gain access to expert construction services, tailored specifically to your area through our
              advanced pin code matching system. Our mission is to make your construction journey as easy and transparent as possible, with customer satisfaction at the core of what we do.
            </p>

            <h2 className='text-2xl font-semibold mt-6'>Customer Service and Support</h2>
            <p>
              We pride ourselves on delivering excellent customer service. From the moment you sign up, our dedicated support team is available to assist you 
              with any questions or concerns you may have. Whether it’s about selecting the right service, understanding our processes, or following up on a project, 
              we’re here to help. Our goal is to make your experience on HomeGurus as smooth and satisfying as possible.
            </p>

            <h2 className='text-2xl font-semibold mt-6'>Cancellation Policy</h2>
            <ul className='space-y-5'>
              <li>
                Cancellations are accepted if requested immediately after placing the order. If the service has already been scheduled with our certified professionals, 
                cancellations may not be possible, as preparations may already be underway.
              </li>
              <li>
                Certain services, such as those involving perishable materials, are not eligible for cancellations. However, we do offer a refund or replacement if the service 
                quality does not meet your expectations.
              </li>
            </ul>

            <h2 className='text-2xl font-semibold mt-6'>Refund Policy</h2>
            <ul className='space-y-5'>
              <li>
                If you receive a service that does not match the description or is defective, please contact our Customer Service team within 2 business days. 
                We will work with the service provider to verify the issue and process your refund if applicable. Our goal is to complete refunds within <strong>2-3 working days</strong>, 
                ensuring a swift and hassle-free process.
              </li>
              <li>
                Refunds are securely processed through our integrated payment system, offering a safe and reliable experience for all transactions.
              </li>
            </ul>

            <h2 className='text-2xl font-semibold mt-6'>Why Choose HomeGurus?</h2>
            <p>
              HomeGurus connects you with top-notch engineers, architects, and contractors based on your specific needs and location. Our platform matches your service 
              requests with local, certified providers, improving efficiency and ensuring that you receive bids from relevant professionals. With the ability to compare 
              ratings, reviews, and cost estimates, you can make an informed decision with ease.
            </p>
            <p>
              Once you choose a bid, our secure payment system allows you to book the service and move forward with confidence. Every step of the way, HomeGurus supports 
              you in realizing your construction goals, while our refund policy offers peace of mind, knowing that any approved refunds will be processed within <strong>2-3 working days</strong>.
            </p>
          </article>
        </div>
      </section>
    </React.Fragment>
  );
};

export default RefundPolicy;
