import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

function ServiceFeedbackModal({ isOpen, onClose, serviceId, userId }) {
  const [rating, setRating] = useState(0);

  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/feedback`, {
          params: {
            service_id: serviceId,
            user_id: userId,
            start: 0
          },
          headers: {
            "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
          }
        });
  
        if (response.data && response.data.feedback) {
          const feedback = response.data.feedback; // Assuming feedback is directly in the response
          setRating(parseInt(feedback, 10)); // Set the rating state based on fetched feedback
        } else {
          setRating(0); // Set rating to 0 if no feedback is found
        }
      } catch (error) {
        console.error("Error fetching feedback:", error);
        setRating(0); // Also set rating to 0 in case of an error
      }
    };
  
    if (isOpen) {
      fetchFeedback();
    }
  }, [isOpen, serviceId, userId]);

  const handleRating = (value) => {
    setRating(value);
  };

  const handleSubmit = async () => {
    if (rating === 0) {
      toast.info("Please select a star rating before submitting.");
      return; // Prevent submission
    }

    const feedbackData = {
      service_id: serviceId,
      user_id: userId,
      feedback: rating.toString() // Sending the rating as feedback
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/feedback`, 
        feedbackData, 
        {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
          }
        }
      );
      toast.success("Thank you for your feedback!");
      onClose(); // Close the modal after submission
    } catch (error) {
      toast.error("Error submitting feedback. Please try again later.");
      console.error("Error submitting feedback:", error);
    }
  };

  if (!isOpen) return null; // Render nothing if modal is closed

  const feedbackMessage = () => {
    switch (rating) {
      case 0:
        return "Your feedback will be valuable for us to provide you the best service.";
      case 1:
        return "We're sorry to hear that! We appreciate your input.";
      case 2:
        return "Thank you for your feedback! We'll strive to improve.";
      case 3:
        return "Thanks! We appreciate your thoughts.";
      case 4:
        return "That's great! We're glad you're satisfied with our work.";
      case 5:
        return "Excellent! Thank you for your kind feedback.";
      default:
        return "";
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-5 rounded-lg shadow-lg w-96">
          <h2 className="text-center text-xl mb-4">Thanks for the Rating</h2>
          
          {/* Star rating */}
          <div className="text-center mb-4 cursor-pointer">
            {[1, 2, 3, 4, 5].map((star) => (
              <span
                key={star}
                className={`text-4xl ${star <= rating ? 'text-yellow-500' : 'text-gray-300'}`}
                onClick={() => handleRating(star)}
              >
                ★
              </span>
            ))}
          </div>

          {/* Feedback message */}
          <p className="text-center mb-4">{feedbackMessage()}</p>
          
          {/* Action buttons */}
          <div className="flex justify-between p-3">
            <button className="btn-primary-md" onClick={onClose}>Close</button>
            <button className="btn-primary-md" onClick={handleSubmit}>Submit</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceFeedbackModal;
