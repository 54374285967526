import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const VendorSubscription = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();

  // Handle phone number input change
  const handleInputChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  // Handle form submit (proceed button click)
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        "https://app.homegurus.in/api/vendor/confirm",
        {
          phone: phoneNumber,
        }
      );

      // Assuming the response structure is correct
      const { vendor_id, name } = response.data.data;

      // Navigate to /subscription with vendorId, name, and phone as state
      navigate("/subscription", {
        state: { vendorId: vendor_id, name, phone: phoneNumber },
      });
    } catch (error) {
      toast.error("Failed to fetch vendor");
      // alert("There was an error. Please try again.");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
          <p className="text-center text-gray-600 mb-8">
            Enter your number to proceed
          </p>
          <form onSubmit={handleFormSubmit} className="space-y-4">
            {/* Phone Input */}
            <div className="input-group">
              <label htmlFor="phone" className="input-label block mb-2">
                Enter your phone number <em>*</em>
              </label>
              <input
                type="tele"
                pattern="[0-9]{10}"
                name="phone"
                className="input-box-md w-full p-3 border border-gray-300 rounded-md"
                placeholder="Enter Phone (10 Digits)"
                value={phoneNumber}
                onChange={handleInputChange}
                required
                maxLength="10"
                onInput={(e) => {
                  if (e.target.value === "") {
                    e.target.value = ""; // Set the value to an empty string when cleared
                  } else {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }
                }}
                
              />
            </div>

            {/* Proceed Button */}
            <button
              type="submit"
              className="btn-primary-md w-full bg-ascent text-white py-2 rounded-md"
            >
              Proceed
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default VendorSubscription;
