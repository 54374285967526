import React, { useEffect, useState } from 'react';
import PageHeader from '../../components/Common/PageHeader/PageHeader';
import axios from 'axios';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { RxExternalLink } from 'react-icons/rx';

const ServiceDetails = () => {

  const [service, setService] = useState();

  const { service_slug } = useParams();

  useEffect(() => {
    try {
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/service/${service_slug}`)
        .then((response) => {
          setService(response.data.data);
          // console.log(response.data);
        });
    } catch (error) {
      console.log(error);
    }
  }, [service_slug]);

  if (!service) {
    return "Loading";
  }

  return (
    <React.Fragment>
      <PageHeader title={service.name} description={service.summary} crums={[{
        label: "Services",
        path: "/services"
      },{
        label: service.name,
        path: `/service/${service_slug}`
      }]} background={`${process.env.REACT_APP_STORAGE_BASE_URL}/${service.cover_image}`} />
      <section>
        <div className='container py-10 space-y-4'>

          <div className='text-base' dangerouslySetInnerHTML={{ __html: service.description}}>

          </div>

          <hr />

          <div className='space-y-3'>
            <h1 className='text-2xl font-semibold'>Make a Quote for {service.name}</h1>
            <Link to={`/make-quote/${service_slug}`} className='btn-primary-md flex items-center space-x-2 w-fit'>
              <span>Service Request</span>
              <RxExternalLink size={15} strokeWidth={0.5} />
            </Link>
          </div>

        </div>
      </section>
    </React.Fragment>
  )
}

export default ServiceDetails